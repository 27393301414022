<template>
  <base-expansion-panel-group v-model="internalValue">
    <base-expansion-panel
      title-class="grey-lighten-3 px-3 py-3 text-body-3 text-md-body-2"
      :init-active="value"
      lazy
      :disabled="required"
    >
      <template #title="{ isActive }">
        <base-icon v-show="isActive" mdi="check" class="mr-2"></base-icon>
        <template v-if="cost > 0">
          {{
            $t('productDetailPage.individualProduct.customizeProduct', [
              $options.filters.price(cost),
            ])
          }}
        </template>
        <template v-else>
          {{
            $t(
              'productDetailPage.individualProduct.customizeProductWithoutCost'
            )
          }}
        </template>
      </template>

      <template #default>
        <div class="grey-lighten-4 px-3 py-5">
          <product-individualisation-field-container
            v-for="field in fields"
            :key="field.id"
            v-model="field.value"
            :field-name="field.id"
            :errors="field.errors"
            :items="field.items"
          ></product-individualisation-field-container>

          <product-individualisation-render-card
            :fields="fields"
            :render-type="renderType"
          ></product-individualisation-render-card>

          <div class="pink-berry--text text-body-4 text-md-body-3 mt-5">
            {{ $t('productDetailPage.individualProduct.changesDeliveryTime') }}
          </div>
        </div>
      </template>
    </base-expansion-panel>
  </base-expansion-panel-group>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseExpansionPanel from '~/components/base/expansion/BaseExpansionPanel'
import BaseExpansionPanelGroup from '~/components/base/expansion/BaseExpansionPanelGroup'
import ProductIndividualisationFieldContainer from '~/components/product/individualisation/field/ProductIndividualisationFieldContainer'

export default {
  name: 'ProductIndividualisationCard',
  components: {
    BaseExpansionPanelGroup,
    BaseExpansionPanel,
    BaseIcon,
    ProductIndividualisationRenderCard: () =>
      import(
        /* webpackPrefetch: true */
        '~/components/product/individualisation/ProductIndividualisationRenderCard'
      ),
    ProductIndividualisationFieldContainer,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    renderType: {
      type: String,
      default: 'default',
    },
    value: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    cost: {
      type: Number,
      default: 5.0,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value ? 0 : undefined
      },
      set(value) {
        this.$emit('input', value !== undefined)
      },
    },
  },
}
</script>
