<template>
  <component
    :is="component"
    v-if="component"
    v-bind="$attrs"
    v-on="$listeners"
  ></component>
</template>

<script>
const fieldMapping = {
  text: 'ProductIndividualisationFieldText',
  textTwo: 'ProductIndividualisationFieldTextTwo',
  textThree: 'ProductIndividualisationFieldTextThree',
  date: 'ProductIndividualisationFieldDate',
  color: 'ProductIndividualisationFieldColor',
  font: 'ProductIndividualisationFieldFont',
  size: 'ProductIndividualisationFieldSize',
  time: 'ProductIndividualisationFieldTime',
  weight: 'ProductIndividualisationFieldWeight',
}

export default {
  name: 'ProductIndividualisationFieldContainer',
  props: {
    fieldName: {
      type: String,
      required: true,
    },
  },
  computed: {
    component() {
      if (!Object.prototype.hasOwnProperty.call(fieldMapping, this.fieldName)) {
        return null
      }

      return () => ({
        component: import(
          `@/components/product/individualisation/field/${
            fieldMapping[this.fieldName]
          }.vue`
        ),
      })
    },
  },
}
</script>
